export enum AMPLITUDE_TYPES {
  viewSubscriptionPage = 'View subscription page',
  openBillingPage = 'Open billing page',
  createdSubscription = 'Created subscription',
  cashFlowReport = 'Cash flow report',
  PnLReport = 'P&L report',
  calendarReport = 'Calendar report',
  deleteOperation = 'Delete operation',
  createOperation = 'Create operation',
  projectsReport = 'Projects report',
  receivableReport = 'Receivable report',
  payableReport = 'Payable report',
  import = 'Import',
  companyCreate = 'Company create',
  integration = 'Integration',
  integrationDeleted = 'Integration deleted',
  bankStatementReport = 'Bank statement report',
  historyReport = 'History report',
  undoOperations = 'Undo operations',
  mergeToTransfer = 'Merge to transfer',
  changeToTransfer = 'Change to transfer',
  invite = 'Invite',
  acceptInvite = 'Accept invite',
  createInvoice = 'Create invoice',
  deleteInvoice = 'Delete invoice',
  acceptInvoice = 'Accept invoice',
  futurePrediction = 'Future prediction',
  transferPrediction = 'Transfer prediction',
  onboardingStep1Hello = 'Step 1 - Hello',
  onboardingStep2Value = 'Step 2 - Value',
  onboardingStep3AddedOperations = 'Step 3 - Added operations',
  onboardingStep4ShowValue = 'Step 4 - Show value',
  onboardingStep5Finish = 'Step 5 - Finish',
  error500 = 'Error 500',
  search = 'Search',
  bulkChanges = 'Bulk changes',
  editOperation = 'Edit operation',
  createEntity = 'Create entity',
  editEntity = 'Edit entity',
  deleteEntity = 'Delete entity',
  createRule = 'Create rule',
  deleteRule = 'Delete rule',
  ruleExecuted = 'Rule executed',
  integrationBelvoTry = 'integration_belvo_try',
  integrationBelvoSuccess = 'integration_belvo_success',
  studyVideo = 'study_video',
  logSearch = 'log_search',
  logRangeSearch = 'log_range_search',
  splitWithFuture = 'split_with_future',
  bulkMultyEdit = 'bulk_multy_edit',
  faqRedirect = 'faq_redirect',
  capilotCreated = 'capilot_created',
  openedHeaderHistory = 'opened_header_history',
  settingEmojiBalance = 'setting_emoji_balance',
  settingEmojiSumOperation = 'setting_emoji_sum_operation',
  analyticsCalendarCopy = 'analytics_calendar_copy',
  transactionsMultiboxPeriod = 'transactions_multibox_period',
  analyticsBalance = 'analytics_balance',
  analyticsBalanceExport = 'analytics_balance_export',
  filterHandoverOwner = 'filter_handover_owner',
  filterHandover = 'filter_handover',
  integrationMonoEq = 'integration_mono_eq',
}

export type ActionEventCommonData = {
  videoType?: '';
};
