import { ActionEventCommonData, AMPLITUDE_TYPES } from '@finmap/core-constants';

import { IntegrationTypeId, NordigenAccount } from '../integrations/types';
import api from './api';
import { UserGeoLocationProps } from './api.types';

export const GET_VERSION_PENDING = 'GET_VERSION_PENDING';
export const GET_VERSION_SUCCESS = 'GET_VERSION_SUCCESS';

export const GET_VERSION_UI_PENDING = 'GET_VERSION_UI_PENDING';
export const GET_VERSION_UI_SUCCESS = 'GET_VERSION_UI_SUCCESS';

export const GET_SCHEDULE_PERIODS_PENDING = 'GET_SCHEDULE_PERIODS_PENDING';
export const GET_SCHEDULE_PERIODS_SUCCESS = 'GET_SCHEDULE_PERIODS_SUCCESS';

export const SET_SERVER_ERROR = 'SET_SERVER_ERROR';
export const RESET_SERVER_ERROR = 'RESET_SERVER_ERROR';

export const SET_CREATE_ELEMENT_ERROR = 'SET_CREATE_ELEMENT_ERROR';
export const RESET_CREATE_ELEMENT_ERROR = 'RESET_CREATE_ELEMENT_ERROR';

export const SET_CREATE_ELEMENT_LOADING = 'SET_CREATE_ELEMENT_LOADING';
export const RESET_CREATE_ELEMENT_LOADING = 'RESET_CREATE_ELEMENT_LOADING';

export const SET_SEARCH_FOCUSED = 'SET_SEARCH_FOCUSED';

export const GET_ACTIVITIES_PENDING = 'GET_ACTIVITIES_PENDING';
export const GET_ACTIVITIES_SUCCESS = 'GET_ACTIVITIES_SUCCESS';

export const SET_SERVER_TIME = 'SET_SERVER_TIME';
export const SET_SHOW_RESTRICTED_DIALOG = 'SET_SHOW_RESTRICTED_DIALOG';
export const SET_SHOW_LEFT_MENU = 'SET_SHOW_LEFT_MENU';
export const SET_CONTAINER_OFFSET = 'SET_CONTAINER_OFFSET';
export const SET_USER_GEO_DATA = 'SET_USER_GEO_DATA';
export const GET_USER_GEO_LOCATION_DATA = 'GET_USER_GEO_LOCATION_DATA';
export const SET_AMOUNT_EMOJI = 'SET_AMOUNT_EMOJI';
export const SET_PAYMENT_EMOJI = 'SET_PAYMENT_EMOJI';
export const SET_SHOW_COINS = 'SET_SHOW_COINS';
export const SET_SHOW_EMPTY_STATE_TOOLTIP_V3 =
  'SET_SHOW_EMPTY_STATE_TOOLTIP_V3';
export const SET_SYNC_INTEGRATIONS_COUNT = 'SET_SYNC_INTEGRATIONS_COUNT';
export const SET_SYNC_INTEGRATIONS = 'SET_SYNC_INTEGRATIONS';
export const SET_COMPLITED_INTEGRATIONS = 'SET_COMPLITED_INTEGRATIONS';
export const SET_FIRST_INTEGRATION_SYNC = 'SET_FIRST_INTEGRATION_SYNC';

export const SET_ACTION_EVENT = 'SET_ACTION_EVENT';

const setAmountEmoji = (emoji: string) => ({
  type: SET_AMOUNT_EMOJI,
  payload: { emoji },
});

const setShowCoins = (showCoins: boolean) => ({
  type: SET_SHOW_COINS,
  payload: { showCoins },
});

const setPaymentEmoji = (emoji: string) => ({
  type: SET_PAYMENT_EMOJI,
  payload: { emoji },
});

const setShowEmptyStateTooltipV3 = (show: boolean) => ({
  type: SET_SHOW_EMPTY_STATE_TOOLTIP_V3,
  payload: { show },
});

const apiToGetGeoLocationData = async () => {
  const { data } = await api.getGeoLocationData();

  return data;
};

const setShowLeftMenu = (showLeftMenu: boolean) => ({
  type: SET_SHOW_LEFT_MENU,
  payload: { showLeftMenu },
});

const setContainerOffset = (containerOffset: number) => ({
  type: SET_CONTAINER_OFFSET,
  payload: { containerOffset },
});

const setShowRestrictedDialog = (value: boolean) => ({
  type: SET_SHOW_RESTRICTED_DIALOG,
  payload: { value },
});

const setServerTime = (time: number) => ({
  type: SET_SERVER_TIME,
  payload: { time },
});

const resetCreateError = () => ({ type: RESET_CREATE_ELEMENT_ERROR });

const resetServerError = () => ({ type: RESET_SERVER_ERROR });

const setSearchFocused = (focused: boolean) => ({
  type: SET_SEARCH_FOCUSED,
  payload: { focused },
});

const setUserGeoData = (userGeoData: UserGeoLocationProps) => ({
  type: SET_USER_GEO_DATA,
  payload: { userGeoData },
});

const getUserLocationData = () => ({
  type: GET_USER_GEO_LOCATION_DATA,
  payload: apiToGetGeoLocationData(),
});

const setCreateError = () => ({ type: SET_CREATE_ELEMENT_ERROR });

const setSyncIntegrationsCount = (data: number) => ({
  type: SET_SYNC_INTEGRATIONS_COUNT,
  payload: { data },
});

const setSyncIntegrations = (data: boolean) => ({
  type: SET_SYNC_INTEGRATIONS,
  payload: { data },
});

const updateCompletedIntegrations = (data: {
  data: {
    integrationId: string;
    status: string;
    integrationTypeId: IntegrationTypeId;
    savedData?: NordigenAccount[];
  }[];
  integrationTypeId: IntegrationTypeId;
}) => ({
  type: SET_COMPLITED_INTEGRATIONS,
  payload: { data },
});

const updateSyncFirstIntegration = (data: boolean) => ({
  type: SET_FIRST_INTEGRATION_SYNC,
  payload: { data },
});

const setActionEvent = (
  event: AMPLITUDE_TYPES,
  data: ActionEventCommonData = {},
) => ({
  type: SET_ACTION_EVENT,
  payload: { event, data },
});

export default {
  setShowCoins,
  setServerTime,
  setAmountEmoji,
  setPaymentEmoji,
  setUserGeoData,
  setCreateError,
  setShowLeftMenu,
  resetCreateError,
  resetServerError,
  setSearchFocused,
  setContainerOffset,
  getUserLocationData,
  setShowRestrictedDialog,
  setShowEmptyStateTooltipV3,
  setSyncIntegrationsCount,
  setSyncIntegrations,
  updateCompletedIntegrations,
  updateSyncFirstIntegration,
  setActionEvent,
};
