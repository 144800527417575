import { MenuItem, MenuList, Typography } from '@material-ui/core';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { EditIcon } from '../../atoms';
import { useStyles } from './styles';
import { UserFiltersProps } from './types';
import { useCallback } from 'react';

export function UserFilters(props: UserFiltersProps) {
  const {
    filters,
    onItemClick,
    onEditClick,
    focusedRowId,
    onItemMouseEnter,
    onItemMouseLeave,
    onOpenFiltersDialog,
    isOwner,
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const onEmployeeItemClick = useCallback(() => {
    onItemClick(true);
  }, [onItemClick]);

  const employeeFilters = filters.filter((el) => isOwner && el.showForOwner);

  return (
    <div
      className={cn(classes.popupRoot, classes.paddingRoot, classes.rootMargin)}
    >
      <div className={!!employeeFilters.length ? classes.containerRow : null}>
        <div
          className={cn(
            classes.maxWidth,
            !!employeeFilters.length ? classes.rightBorder : null,
          )}
        >
          <Typography
            className={cn(
              classes.popupTitle,
              classes.paddingRow,
              classes.noMargin,
            )}
          >
            {t('filters.user.emptyTitle').toUpperCase()}
          </Typography>
          <MenuList
            className={cn(
              classes.listRoot,
              classes.listRootMargin,
              'scrollable-list',
            )}
          >
            {filters
              .filter((el) => !isOwner || !el.showForOwner)
              .map((el) => (
                <MenuItem
                  className={classes.menuRow}
                  key={el._id}
                  id={el._id}
                  onClick={() => onItemClick()}
                  onMouseEnter={onItemMouseEnter}
                  onMouseLeave={onItemMouseLeave}
                >
                  <Typography className={classes.textOverflow}>
                    {el.name}
                  </Typography>
                  <EditIcon
                    onClick={onEditClick}
                    className={
                      focusedRowId === el._id ? classes.visible : classes.hidden
                    }
                  />
                </MenuItem>
              ))}
          </MenuList>
          <Typography
            className={cn(classes.popupLink, classes.paddingRow)}
            onClick={onOpenFiltersDialog}
          >
            {t('filters.user.createNew')}
          </Typography>
        </div>
        {!!employeeFilters.length ? (
          <div className={classes.maxWidth}>
            <Typography
              className={cn(
                classes.popupTitle,
                classes.paddingRow,
                classes.noMargin,
              )}
            >
              {t('filters.user.employeeFilters').toUpperCase()}
            </Typography>
            <MenuList
              className={cn(
                classes.listRoot,
                classes.listRootMargin,
                'scrollable-list',
              )}
            >
              {employeeFilters.map((el) => (
                <MenuItem
                  className={cn(classes.menuRow, classes.menuRowEmployeeHeight)}
                  key={el._id}
                  id={el._id}
                  onClick={onEmployeeItemClick}
                  onMouseEnter={onItemMouseEnter}
                  onMouseLeave={onItemMouseLeave}
                >
                  <div
                    className={cn(
                      classes.containerColumn,
                      classes.maxWidthLess,
                    )}
                  >
                    <Typography className={classes.textOverflow}>
                      {el.name}
                    </Typography>
                    <Typography
                      className={cn(classes.textOverflow, classes.employeeInfo)}
                    >
                      {el.employeeInfo?.name || ''}{' '}
                      {el.employeeInfo?.email
                        ? `(${el.employeeInfo?.email})`
                        : ''}
                    </Typography>
                  </div>
                </MenuItem>
              ))}
            </MenuList>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default UserFilters;
